import React, { Component } from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import { getTranslations, changeToLocalLinks } from "../components/helpers/helpers";
import i18Data from '../../content/intl/labels.json';

import "../styles/teaching.scss"

class Teaching extends Component {

    componentDidMount() {
        this.windowRef = window;
        this.documentRef = document;
        this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [ i18Data.common.url, i18Data.common.altUrl ]);
    }

    componentWillUnmount() {
        if (this.isHtml) {
            this.isHtml.removeEventListener("click", this.linksUnsubs);
        }
    }

    render() {
        const { data: { pageData }, pageContext: { lang } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, "teaching");

        return (
            <Layout mainClassName="teaching" data={{}} noActiveMenu={true} pageContext={this.props.pageContext} translations={translations}>

                <SEO title={page.frontmatter.title} description={page.frontmatter.summary} lang={lang ? lang : "en" }/>
                
                <div className="teaching-container initial-padding">
                    <div className="teaching-top">
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.summary }}></h1>                        
                    </div>

                    <div className="is-html with-headers max-width" 
                        ref={(t) => { this.isHtml = t }}
                        dangerouslySetInnerHTML={{ __html: page.html }}></div>                     

                </div>

            </Layout>
        )
    }
}

export default Teaching


export const query = graphql`
    query TeachingQuery {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "teaching" }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                    }
                    id
                    html

                }
            }
        }

    }
`